/* Slider */
#myCarousel {
  > div > img {
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  a:hover {
    text-decoration: none;
  }

  > .carousel-inner {
    background-position: top center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 10;

    > .item > img {
      width: 100%;
    }
  }
}
