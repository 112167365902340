/**
 * Main App Styles
 */

// Custom variables
@import 'variables';

// Libraries
@import 'libs';

// Mixins
@import 'mixins';

// Custom styles
@import 'custom';

// font-family: 'Roboto Slab', serif;
// font-family: 'Roboto', sans-serif;

/**
 * HTML/BODY
 */

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  @include media-breakpoint-down(lg) {
    overflow-x: hidden;
  }
}

h1 {
  color: $brand-primary;
}

h3 {
  font-size: 1.5rem;
}

p {
  color: $brand-black;
  font-size: 1rem;
}

// Start
#start {
  //background-image: url("Bilder/under_construction_20180502.png");
  background-position: bottom;
  background-repeat: repeat;
  height: auto;
  min-height: 100%;
  padding: 2rem 0;
  @include flex-middle;
  width: 100%;

  #choice {
    display: flex;
    justify-content: space-around;
    width: 100%;
    @include media-breakpoint-down(lg) {
      //height: 85%;
    }
    @include media-breakpoint-up(md) {
      //height: 60%;
      max-height: 500px;
    }
    @include media-breakpoint-down(sm) {
      //height: 40%;
    }
    @include media-breakpoint-down(xs) {
      //flex-direction: column;
      //width: 50%;

    }

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    a {
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(xs) {
        //justify-content: center;
      }

      img {
        border: 6px solid $brand-primary;
        border-radius: 50px;
        height: auto;
        max-height: 100%;
        width: auto;
      }
    }

    h2 {
      font-size: 1.5rem;
      margin-top: 1rem;
      text-align: center;
    }

  }
}

// FWL

header {
  //background:url('#{$path-images}/bg_feuerwehrlauf.jpg') no-repeat center/100%;
  align-items: flex-end;
  display: flex;
  justify-content: center;
  min-height: 42vw;
  padding-bottom: 10vw;
  //border-bottom: 8px solid #fff;

  > #greeting {
    @include fix-width;
    @include media-breakpoint-down(sm) {
      margin-bottom: -10px;
    }

    > p {
      color: #fff;
      font-family: 'Roboto Slab', serif;
      font-size: 5rem;
      font-weight: 900;


      @include media-breakpoint-down(xl) {
        font-size: 4rem;
      }
      @include media-breakpoint-down(lg) {
        font-size: 3rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
        line-height: 1;
      }

    }

    > .lag {
      color: #d60b0b;
      font-size: 4rem;

      @include media-breakpoint-down(md) {
        font-size: 3rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
        line-height: 1;
      }

    }
  }
}

nav {
  @include fix-width();
  display: flex;
  height: auto;
  @include media-breakpoint-down(xl) {
    width: 90%;
    &.fixed-top {
      right: unset;
    }
  }
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    width: 90%;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 10px;
  }

  /*a {
    @include media-breakpoint-down(xl) {
      font-size: 1.6rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 1.1rem;
    }
  }*/

  > .navbar-brand {
    > img {
      height: 7vw;
      min-height: 35px;
      width: auto;
      @include media-breakpoint-down(md) {
        margin-top: 25%;
      }
    }
  }

  button {
    &.navbar-toggler {
      border-color: rgb(255, 255, 255);
    }

    &.bg-gray {
      background: rgba(0, 0, 0, 0.5);
    }

    .navbar-toggler-icon {
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'rgba(255, 255, 255, 1)\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/%3E%3C/svg%3E');

    }
  }

  #myNavbar {

    > ul {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0 20px 20px 0;
      justify-content: space-between;
      width: 100%;

      .nav-link {
        color: #fff;
        font-family: 'Roboto Slab', serif;
        font-size: 1.7rem;
        text-transform: uppercase;
        white-space: nowrap;
        @include media-breakpoint-down(lg) {
          font-size: 1.3rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }
        @include media-breakpoint-down(sm) {
          font-size: 1.1rem;
        }
      }

      .nav-link:hover {
        color: $brand-primary;
      }

      .dropdown-menu {
        border: 1px solid $brand-primary;

        .dropdown-item:hover {
          background: $brand-primary;
          color: #fff;

        }
      }
    }

    &.bg-gray {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &.bg-move {
    //background: rgba(0, 0, 0, 0.5);
    border-radius: 0 20px 20px 0;
    height: $menu-height;
    margin-top: 5px;

    @include media-breakpoint-down(lg) {
      border-radius: 0;
    }
    @include media-breakpoint-down(md) {
      height: $menu-height - 15px;
    }

    > .navbar-brand {
      padding-bottom: 0;
      padding-top: 0;

      > img {
        height: $menu-height;
        @include media-breakpoint-down(md) {
          height: $menu-height - 15px;
        }
      }

    }

    > #myNavbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            font-size: 1.2rem;
            @include media-breakpoint-down(md) {
              font-size: 1.2rem;
            }

            &.nav-link-move {
              //color: $brand-primary;
            }
          }


        }
      }
    }

  }
}

main {
  box-shadow: inset 0 30px 30px -5px #fff;
  display: flex;
  //background: url("#{$path-images}/back_unten.png") fixed;
  min-height: 36vh;
  width: 100%;
  //@include flex-center();

  > #content {

    @include fix-width;

    margin-top: -10%;

    .text {
      min-height: 43.1vw;
      padding: 5% 5% 4vw 5%;

      //background: #fff;
      z-index: 10;
      @include media-breakpoint-down(md) {
        padding: 5% 0 4vw 0;
      }

      h1 {
        color: #000;

        @include media-breakpoint-down(md) {
          font-size: 2.2rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: 1.8rem;
        }

        @include media-breakpoint-down(xs) {
          font-size: 1.5rem;
        }

      }

      img {
        width: auto;
      }

      iframe {
        height: 20vw;
        width: 100%;
        @include media-breakpoint-down(md) {
          height: 25vw;
        }

        @include media-breakpoint-down(sm) {
          height: 40vw;
        }
      }

      #news {
        margin: -15vh auto 0 auto;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        &.card-deck {
          margin: auto 0;

          .card {
            margin: 0;
            max-width: calc(50% - 15px);

            &:nth-child(even) {
              //margin-left: 15px;
            }

            &:nth-child(odd) {
              //margin-right: 15px;

            }

            @include media-breakpoint-down(md) {
              max-width: unset;
            }

            .card-body {
              .news-image {
                align-items: center;
                display: flex;
                flex-direction: row;
                margin-bottom: 1.5rem;
                max-height: 200px;
                overflow: hidden;

                img {
                  height: 100%;
                  transition: all .3s ease-in-out;
                  width: 100%;
                }
              }

              .news-content {
                background: #fff;
                bottom: 0;
                padding-top: 10px;
                position: absolute;
                width: 88%;


                .teaser {
                  /*display: none;*/
                  font-size: 14px;
                  height: 0;
                  line-height: 1.8em;
                  overflow: hidden;
                  transition: all .3s ease-in-out;

                  p {
                    overflow: hidden;
                    text-align: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                .card-title {
                  color: $brand-black;
                }
              }


              .rnav {
                text-align: right;
              }

            }

            &:hover {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

              img {
                transform: scale(1.1);
              }

              .teaser {
                height: auto !important;
              }
            }

            &:first-child {
              margin: 0;
              max-width: unset;

              .news-image {
                max-height: 400px;
              }

              .news-content {
                width: 94.2%;
              }

              &:hover {
                .teaser {
                  height: auto !important;
                }
              }
            }

          }
        }

      }

      #results, #report {
        @extend #news;
      }

      #athletes {

        table tr td:first-child {
          min-width: 100px;
          vertical-align: top;
        }

      }

      #galery {
        @extend #news;

        .card {
          max-width: unset !important;
        }

        .card-body {
          padding: 0.5rem;

          .news-image {
            margin-bottom: 0 !important;
            position: relative;
            z-index: 1;
            max-height: 400px !important;

            @media (max-width: 1100px) {
              max-height: 350px !important;
            }

            @media (max-width: 900px) {
              max-height: 270px !important;
            }

            @include media-breakpoint-down(sm) {
              max-height: 350px !important;
            }

            @media (max-width: 650px) {
              max-height: 300px !important;
            }

            @include media-breakpoint-down(xs) {
              max-height: 250px !important;
            }

            @media (max-width: 500px) {
              max-height: 200px !important;
            }

            .galery-content {
              background: #fff;
              height: 4rem;
              margin-top: 35%;
              opacity: 0.6;
              position: absolute;
              width: 100%;
              z-index: 1000;

              .card-title {
                color: #000;
                font-weight: 600;
                height: 100%;
                margin-bottom: 0;
                width: 100%;
                @include flex-middle;
              }
            }
          }
        }


      }

      .posting {
        .news-picture {
          overflow: hidden;

          img {
            //margin: -5% 0 0 -5%;
            height: 100%;
            width: 100%;
            @include media-breakpoint-down(sm) {
              //margin: -4% 0 0 -3%;
              //width: 106%;
            }
          }
        }

        .news-body {
          padding: 5% 5% 0 5%;

          .news-text {
            padding-top: 5%;
          }

        }

        .news-nav {
          padding: 5%;

          .next {
            text-align: right;
          }
        }
      }

      .sponsors-all {
        @include media-breakpoint-down(sm) {
          margin: 0 auto;
          width: 75%;
        }

        > .row > div {
          @include flex-middle;

          .sponsors-img {
            margin-bottom: 5%;
            max-height: 200px;
            max-width: 100%;
            visibility: hidden;
            width: auto;
            @include media-breakpoint-down(sm) {
              visibility: visible;
            }
          }
        }

      }

      .galery-detail {
        padding-top: 1rem;

        .thumb {
          margin-bottom: 30px;
          display: flex;
          justify-content: center;
        }
      }


    }

    .sidebar {
      background: #fff;
      margin-bottom: 2vw;
      min-height: 50px;
      padding: 10px;
      @include media-breakpoint-down(sm) {
        margin: 10px auto;
        width: 270px;
      }

      &.sponsors {
        @include flex-middle;
        height: 270px;

        .swiper {
          height: 100%;

          .swiper-slide {
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }

        .carousel-item {
          align-items: center;
          height: 100%;
          justify-content: center;
          width: 100%;

          &.active {
            display: flex;
          }
        }

        img {
          max-height: 100%;
          max-width: 100%;
          width: auto;
        }

      }

      &.fb {

      }

      &.button {
        background: transparent;

        img {
          min-width: 150px;
          width: 100%;
          @include media-breakpoint-down(sm) {
            min-width: 135px;
          }
        }
      }
    }

    > .row {
      margin-left: 0;
      margin-right: 0;

      > .col-lg-9 {
        background: #fff;

        &.bg-fwl {
          background: url('/userfiles/image/Sponsoren/back_feuer_copy.jpg') repeat;

        }
      }
    }
  }

}

footer {
  @include fix-width;

  background: transparent;
  margin-top: -3rem;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  > .row {
    margin-left: 0;
    margin-right: 0;

    .col-lg-9 {
      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #footer-nav {
    background: #fff;
    border-top: 4px dotted $brand-primary;
    display: flex;
    //width: 50%;
    justify-content: space-around;

    p {
      margin: 0;
      padding: 0.5rem 0;
    }

  }

}

.hidden {
  display: none;
}

.extra-text {
  font-size: 12px;
  hyphens: auto;
  text-align: justify;
}

/*
==============================================
fadeIn
==============================================
*/

.fadeIn {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;

  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;

  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;

  visibility: visible !important;
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
  }
  60% {
    -webkit-transform: scale(1.1);
  }
  80% {
    -webkit-transform: scale(0.9);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

img.zoom {
  border-radius: 5px;
  height: 200px;
  object-fit: cover;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  width: 100%;

  @include media-breakpoint-down(xs) {
    height: auto;
  }
}


.transition {
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.modal-header {

  border-bottom: none;
}

.modal-title {
  color: #000;
}

.modal-footer {
  display: none;
}

.feedback {
  color: green;
  font-size: 1.7rem;
}
