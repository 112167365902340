// App mixins.

@mixin page-content() {
  width: 100%;
  height: 100%;
  display: none;
}

@mixin null-padding() {
  padding-left: 0;
  padding-right: 0;
}

@mixin fix-width() {
  max-width: 1200px;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
}

@mixin flex-middle() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin full() {
  width: 100%;
  height: 100%;
}

@mixin size($size-width, $size-height) {
  width: $size-width;
  height: $size-height;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin slider-caption-height($caption-height) {
  height: ($slider-caption-background-height * $caption-height) / 100;
}