// Custom style variables.
// Variables and stuff in English only!
// Add a prefix or suffix like 'list-padding-left' - 'nav-margin-right'
// Better use full words instead of abbreviations, others might not understand them later.
// Use ceil() or floor() to prevent pixel values with comma (this would be 1026.50 px, but there are no 1/2 px)

// Main paths.
$path-assets: '/assets';
$path-images: '#{$path-assets}/img';
$fa-font-path: '../fonts';

$body-bg: #fff;

$brand-primary: #d60b0b;
$brand-secondary: #c6c6c6;
$brand-black: #151515;
$brand-gray: #6f6f6f;

$primary: $brand-primary;

$menu-height: 50px;

$slider-caption-background-height: 90px;

$enable-shadows: yes;

// Main Font
$font-family-base: brandon-grotesque, sans-serif;

// Tabs
$nav-tabs-link-active-color: $brand-primary;
$nav-tabs-link-hover-border-color: $brand-secondary;

// Breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl:1340px
);